import DotNetObject = DotNet.DotNetObject;

// FullCalendar
import {
    Calendar,
    CalendarOptions,
    DatesSetArg,
    DurationInput,
    EventChangeArg, EventClickArg, EventDropArg, EventHoveringArg,
    EventInput,
    PluginDef
} from '@fullcalendar/core';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
    // Draggable,
    DropArg, EventDragStartArg, EventDragStopArg,
    EventResizeDoneArg,
    EventResizeStartArg, EventResizeStopArg
} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';


type FCEventArgType = EventChangeArg | DropArg | EventDragStartArg | EventDragStopArg | EventResizeDoneArg | EventResizeStartArg | EventResizeStopArg

function removeNullFromObject(object: any): any {
    for (const key in object) {
        if (!object.hasOwnProperty(key))
            continue;

        if (object[key] === null || object[key] === "null" || object[key] === undefined || object[key] === "undefined")
            delete object[key]
        else if (Array.isArray(object[key])) {
            object[key] = removeNullFromObjectArray(object[key]);
        } 
    }
    
    return object;
}

function removeNullFromObjectArray(objects: object[]): object[] {
    return objects.map(removeNullFromObject);
}

class FullCalendarWrapper {
    private readonly _calendarEl: HTMLElement;
    private readonly _dotNetReference: DotNetObject;
    private readonly _calendarRef: Calendar;

    constructor(calendarDivId: string, dotNetReference: DotNet.DotNetObject, settings: CalendarOptions, pluginsToLoad: string[]) {
        const calendarEl = document.getElementById(calendarDivId);
        if (calendarEl === null)
            throw new Error("calendarDivId not found")
        
        this._calendarEl = calendarEl;
        this._dotNetReference = dotNetReference;

        settings = {
            ...removeNullFromObject(settings),
            
            plugins: this.getPlugins(pluginsToLoad),
            locales: [frLocale],
            
            events: this.getEvents,

            datesSet: this.onDatesSet,
            drop: this.onDrop,
            
            eventChange: this.onEventChange,
            eventClick: this.onEventClick,
            eventDrop: this.onEventDrop,
            eventResize: this.onEventResize,
            eventResizeStart: this.onEventResizeStart,
            eventResizeStop: this.onEventResizeStop,
            eventMouseEnter: this.onEventMouseEnter,
            eventMouseLeave: this.onEventMouseLeave,
            eventDragStart: this.onEventDragStart,
            eventDragStop: this.onEventDragStop,
        };

        this._calendarRef = new Calendar(
            this._calendarEl,
            settings
        );

        this.render();
    }
    
    private getPlugins = (pluginsToAdd: string[]): PluginDef[] => {
        let plugins: PluginDef[] = [];

        for (const pluginString of pluginsToAdd) {
            if (pluginString.includes('dayGrid')) {
                plugins.push(dayGridPlugin);
            } else if (pluginString.includes('interaction')) {
                plugins.push(interactionPlugin);
            } else if (pluginString.includes('timeGrid')) {
                plugins.push(timeGridPlugin);
            } else if (pluginString.includes('list')) {
                plugins.push(listPlugin);
            //} else if (pluginString.includes('bootstrap')) {
            //    plugins.push(bootstrapPlugin);
            //} else if (pluginString.includes('googleCalendar')) {
            //    plugins.push(googleCalendarPlugin);
            }
        }

        return plugins;
    }

    get dotNetReference(): DotNetObject {
        return this._dotNetReference;
    }

    get calendarReference(): Calendar {
        return this._calendarRef;
    }

    public render = () => {
        this._calendarRef.render();
    }
    
    get dateRange() {
        return { 
            start: this._calendarRef.view.currentStart,
            end: this._calendarRef.view.currentEnd,
        }
    }
    
    //// FROM JS Callbacks
    private onDatesSet = async (dateInfo: DatesSetArg) => {
        await this._dotNetReference?.invokeMethodAsync(
            'OnDatesSetCallback',
            {
                start: dateInfo.start,
                end: dateInfo.end
            }
        );
    };

    private onDrop = async (info: DropArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnDropCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventChange = async (info: EventChangeArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventChangeCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventClick = async (info: EventClickArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventClickCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventDrop = async (info: EventDropArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventDropCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventResize = async (info: EventResizeDoneArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventResizeCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventResizeStart = async (info: EventResizeStartArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventResizeStartCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventResizeStop = async (info: EventResizeStopArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventResizeStopCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventMouseEnter = async (info: EventHoveringArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventMouseEnterCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventMouseLeave = async (info: EventHoveringArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventMouseLeaveCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventDragStart = async (info: EventDragStartArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventDragStartCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private onEventDragStop = async (info: EventDragStopArg) => {
        await this._dotNetReference.invokeMethodAsync(
            'OnEventDragStopCallback',
            this.buildCalendarEventChangeResponse(info)
        );
    };

    private buildCalendarEventChangeResponse = (info: FCEventArgType): any => {
        let response: any = {};

        // if ("draggedEl" in info && info.draggedEl !== undefined) {
        //     response.DataSet = info.draggedEl.dataset.event ? JSON.parse(info.draggedEl.dataset.event) : null;
        //     response.Start = info.date;
        //     response.End = info.date;
        //     response.ResourceId = response.DataSet.resourceId;
        // }
        //

        if ("event" in info) {
            if (info.event === undefined) {
                return removeNullFromObject(response);
            }

            response = {
                Start: info.event.start,
                End: info.event.end,
                Id: info.event.id,
                GroupId: info.event.groupId,
                AllDay: info.event.allDay,
                Title: info.event.title,
                Url: info.event.url,
                BackgroundColor: info.event.backgroundColor,
                TextColor: info.event.textColor,
                ClassNames: info.event.classNames,
                DaysOfWeek: info.event.extendedProps.daysOfWeek,
                StartTime: info.event.extendedProps.startTime,
                EndTime: info.event.extendedProps.endTime,
                StartRecur: info.event.extendedProps.startRecur,
                EndRecur: info.event.extendedProps.endRecur,
                ResourceId: info.event.extendedProps.resourceId,
                ResourceIds: info.event.extendedProps.resourceIds,
            };

            // if ("draggedEl" in info.event) {
            //
            // }
            //
            // if (info.event.draggedEl !== undefined) {
            //     response.DataSet = JSON.parse(info.event.draggedEl.dataset.event);
            // }
        }

        return removeNullFromObject(response);
    };

    private getEvents = async (args: {
        start: Date;
        end: Date;
        startStr: string;
        endStr: string;
        timeZone: string;
    }): Promise<EventInput[]> => {
        const newEvents = await (this._dotNetReference.invokeMethodAsync<EventInput[]>(
            "GetEventsCallback",
            args
        ));

        return removeNullFromObjectArray(newEvents);
    }
    
    //// From Dotnet
    public changeDuration = (units: DurationInput) => {
        this._calendarRef.setOption('duration', units);
    };

    public changeView = (value: string) => {
        this._calendarRef.changeView(value);
    }

    public setOption = (option: any, value: any) => {
        this._calendarRef.setOption(option, value);
    };

    public refetchResources = (newCalendarResourceFeed: string) => {
        this._calendarRef.setOption('events', newCalendarResourceFeed);
        this._calendarRef.refetchEvents();
    };

    public refetchEvents = () => {
        this._calendarRef.refetchEvents();
    };

    public addEvent = (newEvent: EventInput) => {
        this._calendarRef?.addEvent(newEvent);
    }

    public removeEvent = (eventId: string) => {
        const event = this._calendarRef.getEventById(eventId);

        if (event !== null) {
            event.remove();
        }
    }

    public clearEvents = () => {
        const eventList = this._calendarRef.getEvents();

        if (eventList === undefined || eventList.length === 0) {
            return
        }

        eventList.forEach(event => {
            event.remove()
        });
    }
}

export default {
    build: (calendarDivId: string, dotNetReference: DotNetObject, settings: CalendarOptions, pluginsToLoad: string[]): FullCalendarWrapper => {
        return new FullCalendarWrapper(calendarDivId, dotNetReference, settings, pluginsToLoad);
    },
}
